<template>
  <div>
    <div>
      <div class="d-inline-block text-sm-right px-sm-2">
        <v-btn :small="$vuetify.breakpoint.xsOnly" :block="$vuetify.breakpoint.xsOnly"
               class="text-none font-weight-black"
               color="warning darken-1"
               @click="$emit('restart-exercise')">
          Restart
        </v-btn>
      </div>
      <div class="d-inline-block text-sm-left px-sm-2">
        <v-btn :small="$vuetify.breakpoint.xsOnly" :block="$vuetify.breakpoint.xsOnly"
               class="text-none font-weight-black"
               color="success darken-1"
               @click="$emit('finish-exercise')">
          Finish
        </v-btn>
      </div>
      <!--      <v-col cols="6" md="3" class="text-center">-->
      <!--        <v-btn :small="$vuetify.breakpoint.xsOnly" :block="$vuetify.breakpoint.xsOnly" class="text-none"-->
      <!--               color="secondary" @click="saveResults">-->
      <!--          Save Results - -->
      <!--          <app-icon icon="save" size="18"/>-->
      <!--        </v-btn>-->
      <!--      </v-col>-->
    </div>
    <div v-if="graphData && graphData.detections"
         class="my-5 pa-1 frame">
      <accuracy-timeline :data="graphData"/>
    </div>
  </div>
</template>

<script>
// import { mapGetters }          from 'vuex'

export default {
  name:       'ExerciseResults',
  components: {
    AccuracyTimeline: () => import('sections/accuracyTimeline')
  },
  props:      {
    graphData: Object,
    exercise:  Object
  },

  // computed: {
  //   ...mapGetters('user', ['getSavedResults'])
  // },

  methods: {
    // async saveResults() {
    //   await this.getSavedResults.create(exercise, exercise.name)
    //   await this.updateUserExercises()
    // },
  }
}
</script>

<style scoped>
.frame {
  border: 2px #0D388C dashed;
}
</style>